import {
  StepperComponent,
  WCInlineInputFilter,
  GlobalCard,
  useWindowSize,
  useStyles,
  navigateToNextRoute,
} from "@mfe/js-common-ave-uiutils";
import { useForm } from "@mantine/form";
import {
  formatter,
  fetchBusData,
  getAccessTokenApi,
  fetchTraveLiquidatorByIDData,
} from "@mfe/ts-common-ave-utilitaries";
import {
  useIsAuthenticated,
  MsalProvider,
  useMsal,
} from "@mfe/react-common-ave-msal";
import { useEffect, useState } from "react";
import { BusCardComponent } from "../components/busCard.component";

import { FaCalendarDays } from "react-icons/fa6";
import { FaMapMarkerAlt } from "react-icons/fa";
import { LuChevronDown } from "react-icons/lu";

export default function BookBus({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <BookBusComponent organization={organization} />
    </MsalProvider>
  );
}

export const BookBusComponent = ({ organization }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const { classes } = useStyles();
  const isMd = useWindowSize("md");
  const [tokenApi, setTokenApi] = useState("");
  const [travelIndex, setTravelIndex] = useState(null);
  const [finalBus, setFinalBus] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    busList: [],
    travelLiquidatorData: { ViajeTrayecto: [], CodigoEmpleadoViajero: "" },
  });

  const nextBusMap: any = data?.travelLiquidatorData?.ViajeTrayecto?.filter(
    (trip) =>
      trip?.ServicioBpo?.some(
        (service) => service.AliasServicio === "TERRESTRE"
      )
  );

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  useEffect(() => {
    if (tokenApi) {
      fetchTraveLiquidatorByIDData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID"),
        setLoading
      );
    }
  }, [tokenApi]);

  useEffect(() => {
    if (nextBusMap.length) {
      const indice = nextBusMap
        .map((item) => item.ServicioBpo)
        .findIndex((service) =>
          service.some((s) => s.AliasServicio === "TERRESTRE")
        );

      setTravelIndex(indice);
    }
  }, [data?.travelLiquidatorData]);

  useEffect(() => {
    if (travelIndex !== null) {
      fetchBusData(
        tokenApi,
        setData,
        setError,
        setLoading,
        data?.travelLiquidatorData?.CodigoEmpleadoViajero
      );
    }
  }, [travelIndex]);

  useEffect(() => {
    if (finalBus) {
      navigateToNextRoute(data?.travelLiquidatorData);
    }
  }, [finalBus]);

  const formTopFilter = useForm({
    initialValues: {
      originSearchFilter: "",
      destinationSearchFilter: "",
      originFilter: "",
      destinationFilter: "",
      datesFilter: [null, null],
    },
  });

  const topFilterData = [
    {
      md: "auto",
      inputType: "select",
      placeHolder: "Origen",
      value: "originFilter",
      icon: <FaMapMarkerAlt size="1.5rem" color="#004236" />,
      form: formTopFilter.getInputProps("originFilter"),
      data: [],
      searchValue: formTopFilter.values.originSearchFilter,
      onSearchChange: (value) =>
        formTopFilter.setFieldValue("originFilter", value),
      rightSection: <LuChevronDown size="1.2rem" color="#004236" />,
    },
    {
      md: "auto",
      inputType: "select",
      placeHolder: "Destino",
      value: "destinationFilter",
      icon: <FaMapMarkerAlt size="1.5rem" color="#004236" />,
      form: formTopFilter.getInputProps("destinationFilter"),
      data: [],
      searchValue: formTopFilter.values.destinationSearchFilter,
      onSearchChange: (value) =>
        formTopFilter.setFieldValue("destinationFilter", value),
      rightSection: <LuChevronDown size="1.2rem" color="#004236" />,
    },
    {
      md: "auto",
      inputType: "dateInput",
      placeHolder: "Seleccionar Fechas",
      value: "datesFilter",
      icon: <FaCalendarDays size="1.2rem" color="#004236" />,
      form: formTopFilter.getInputProps("datesFilter"),
    },
    {
      lg: 2,
      md: "content",
      inputType: "button",
      title: "Buscar",
      onClick: () => {},
    },
  ];

  const utils = { formatter };

  const busCardComponentProps = {
    navigateToNextRoute,
    StepperComponent,
    WCInlineInputFilter,
    GlobalCard,
    utils,
    classes,
    isMd,
    setFinalBus,
    error,
    loading,
    topFilterData,
    data,
  };

  return isAuthenticated ? (
    <BusCardComponent {...busCardComponentProps} />
  ) : null;
};
