import React from "react";
import {
  Box,
  Grid,
  Text,
  Image,
  Divider,
  Flex,
  Button,
  Loader,
} from "@mantine/core";
import { capitalize } from "lodash";

export const BusCardComponent = ({
  navigateToNextRoute,
  StepperComponent,
  WCInlineInputFilter,
  GlobalCard,
  utils,
  classes,
  isMd,
  error,
  loading,
  topFilterData,
  data,
}) => {
  return (
    <>
      <Box maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
        <StepperComponent servicesData={data?.travelLiquidatorData} />
      </Box>
      {loading ? (
        <Grid maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
          <Grid.Col xs={12}>
            <GlobalCard>
              <Flex
                gap="md"
                justify="center"
                align="center"
                direction="row"
                wrap="wrap"
                mih={isMd ? "auto" : "208px"}
              >
                <Text size="md" fw={700}>
                  Cargando buses disponibles...
                </Text>
                <Loader variant="dots" color="#cbd300" />
              </Flex>
            </GlobalCard>
          </Grid.Col>
        </Grid>
      ) : error.length > 0 ? (
        <Grid maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
          <Grid.Col xs={12}>
            <GlobalCard>
              <Flex
                gap={12}
                justify="center"
                align="center"
                direction="column"
                sx={{ height: isMd ? "auto" : "224px" }}
              >
                <Text fw={700} color="#004236" ta="center">
                  {capitalize(error)}
                </Text>
                <Button
                  className={classes.buttonOutline}
                  data-testid="skip-action-button"
                  onClick={() =>
                    navigateToNextRoute(data?.travelLiquidatorData)
                  }
                >
                  Trayecto sin Bus
                </Button>
              </Flex>
            </GlobalCard>
          </Grid.Col>
        </Grid>
      ) : (
        <>
          <WCInlineInputFilter inputs={topFilterData} scrollY="157" />
          <Grid maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
            {data?.busList?.map((_, index) => (
              <GlobalCard key={index}>
                <Grid
                  sx={{
                    height: isMd ? "auto" : "224px",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col md={3} xs={12}>
                    <Image
                      radius="md"
                      height={180}
                      src="https://th.bing.com/th/id/R.3fdc4093b652d03c8937fa4cc6bbad68?rik=sOgaHngBuDARPA&riu=http%3a%2f%2f2.bp.blogspot.com%2f_7XCtCA-Kiow%2fTO2eLCBqWLI%2fAAAAAAAAABw%2fYiEDtj-he0Y%2fs1600%2fbolivarianologo.jpg&ehk=F8d51Thu5AvLox%2bh0VZVmjA36hV2p2EgpVwpMOcI%2f9g%3d&risl=&pid=ImgRaw&r=0"
                      fit="contain"
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} md={6} sx={{ justifySelf: "center" }}>
                    <Flex justify="space-between">
                      <Box>
                        <Text fw={700}>Salida</Text>
                        <Text fw={700}>5:15 AM</Text>
                        <Text>23-Ene-2024</Text>
                      </Box>
                      <Box>
                        <Text fw={700}>Llegada</Text>
                        <Text fw={700}>7:30 PM</Text>
                        <Text>23-Ene-2024</Text>
                      </Box>
                    </Flex>
                    <Text mt={8}>
                      <strong>Duración:</strong> 14:15:00 horas
                    </Text>
                    <Divider my={8} />
                    <Flex justify="space-between">
                      <Box>
                        <Text fw={700}>Servicios</Text>
                        <Text>Baño-WiFi</Text>
                      </Box>
                      <Box>
                        <Text fw={700}>Asientos</Text>
                        <Text>Disponibles</Text>
                      </Box>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col xs={12} md={3}>
                    <Text ta="center" fw={700} fz="lg">
                      Tarifa total desde
                    </Text>
                    <Text fw={700} fz="lg" ta="center" color="#004236">
                      {utils.formatter.format(60000)}
                    </Text>
                    <Button fullWidth className={classes.button} mt={16}>
                      Seleccionar
                    </Button>
                  </Grid.Col>
                </Grid>
              </GlobalCard>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};
